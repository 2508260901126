import React from 'react';
import '../global.css';

const UnsereLeistungenContent = () => {
  return (
    <div className="content-container common-width">
      <h2><strong>Unsere Leistungen</strong></h2>
      <p>Wir als Ihre Hausarztpraxis verstehen uns als festen Anlaufpunkt für die ganze Familie in den meisten Lebens- bzw. Krankheitslagen.
        Wir legen großen Wert auf das frühe Erkennen und Behandeln von Erkrankungen sowie auf fundierte Vorsorge zur Gesunderhaltung.
        Als Fachärzte für Allgemeinmedizin haben wir eine breite Facharztausbildung genossen, was uns zur Erkennung, Behandlung und ggf. Vorbeugung einer Vielzahl von Krankheitsbildern aus den unterschiedlichsten Fachgebieten qualifiziert. Für die Akutversorgung Ihrer Erkrankung ist die allgemeinmedizinische Praxis zunächst meist der richtige Anlaufpunkt. Ihr behandelnder Arzt wird die, für Ihr individuelles Krankheitsbild notwendigen diagnostischen Schritte in der Praxis durchführen können. Sollte eine weiterführende, fachärztliche Diagnostik oder Therapie notwendig werden, verweisen wir Sie an die richtigen Anlaufstellen. Auch eine umgehende Einweisung wird, wenn nötig organisiert.
        Unser Team betreut Sie und Ihre Familie bei allen Fragen rund um die Themen Gesundheit, Vorsorge oder eingetretener Erkrankungen.
      </p>
      <h2><strong>Hausärztliche Versorgung</strong></h2>

      <ul>
        <li>Durchführung von Hausbesuchen</li>
        <li>Betreuung von Patienten in Pflegeheimen</li>
        <li>Familienmedizin</li>
        <li>Untersuchungen nach Jugendarbeitsschutzgesetz</li>
        <li>Gesundheitsberatung</li>
        <li>Betreuung von Patienten im DMP, für Diabetes Typ 2, Asthma, COPD und KHK</li>
        <li>EKG</li>
        <li>kleine chirurgische Eingriffe</li>
        <li>Spirometrie (Lungenfunktionsuntersuchung)</li>
        <li>Jugenduntersuchung J1</li>
        <li>Jugendschutzuntersuchung</li>
        <li>Check up, Vorsorgeuntersuchungen (Checkup ab 35+, 55+, einmalig auch ab 18)</li>
        <li>Hautkrebsscreening</li>
        <li>Krebsvorsorge für Männer</li>
        <li>Psychosomatische Grundversorgung</li>
        <li>Impfungen</li>
        <li>Belehrung nach dem Infektionsschutzgesetz</li>
        <li>umfangreiche Labordiagnostik</li>
        <li>Urinuntersuchungen</li>
        <li>Führerscheinuntersuchungen</li>
        <li>Reisemedizinische Leistungen</li>
        <li>Ernährungsberatungen</li>
        <li>Schnelltest in der Praxis Quick/INR und Troponin</li>
        <li>Hilfe bei ambulanten, stationären Rehabilitationsanträgen sowie Rentenanträgen</li>
        <li>Wundversorgung durch hausinternes Personal</li>
        <li>OP Vorbereitungen</li>
      </ul>

      <h2><strong>Ultraschalldiagnostik</strong></h2>
      <ul>
        <li>Bauch (Abdomen)</li>
        <li>Schilddrüse</li>
      </ul>

      <h2><strong>Kardiologie</strong></h2>
      <ul>
        <li>24-Stunden-EKG</li>
        <li>Ergometrie (Belastungs-EKG)</li>
        <li>24-Stunden-Blutdruckmessung</li>
        <li>Ruhe-EKG</li>
        <li>Betreuung von Menschen mit chronischer Herzinsuffizienz</li>
      </ul>
      <h2><strong>IGel Leistungen</strong></h2>
      <ul>
        <li>Aufbauinfusionen</li>
        <li>Vitaminkuren</li>
        <li>Medivitan Spritzen</li>
        <li>Vitamin B Spritzen</li>
        <li>Eiseninfusionen</li>
        <li> Kortison Infusionen nach Gehörsturz</li>
      </ul>

      <p>...und vieles mehr<br /></p>

      <h2><strong>Sportmedizinische Untersuchungen</strong></h2>
      <ul>
        <li>Sportmedizinische Leistungsstests (z.B. Fitnessstudios od. diverse andere Sportarten)</li>
        <li>Tauchtauglichkeitsuntersuchungen</li>

      </ul>

    </div>
  );
}

export default UnsereLeistungenContent;
