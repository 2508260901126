import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import '../global.css';

const jobDescriptions = [
    {
        title: "Assistenzarzt/ Assistenzärztin (m/w/d)",
        content: (
            <>
                <h4><strong>Wir suchen dich! Weiterbildungsassistent/in Allgemeinmedizin (m/w/d)</strong></h4>
                <p>
                    Du suchst einen Ort, an dem du deine Leidenschaft für Allgemeinmedizin entfalten und dich gleichzeitig wohlfühlen kannst? Dann bist du bei uns genau richtig!
                    Unsere moderne Hausarztpraxis in Reisbach, nahe Dingolfing, freut sich darauf, dich in unserem herzlichen Team willkommen zu heißen.
                </p>
                <h5><strong>Das erwartet dich:</strong></h5>
                <p>  Wir bieten dir nicht nur einen Arbeitsplatz, sondern eine echte Chance, dich beruflich und persönlich weiterzuentwickeln – und das in einem Umfeld, das Wert auf dein Wohlbefinden legt:</p>

                <ul>
                    <li><strong>Modern und vielseitig:</strong> EKG, Fahrradergometrie, Sonographie, Spirometrie, Langzeitmessungen und Troponinschnelltests – du arbeitest mit moderner Ausstattung und einem breiten Therapiespektrum.</li>
                    <li><strong>Flexibilität, die zu dir passt:</strong> Ob Vollzeit oder Teilzeit – wir finden die Lösung, die in dein Leben passt.</li>
                    <li><strong>Attraktive Konditionen:</strong> Wir bieten dir flexible Arbeitszeiten, eine attraktive Honorierung und Unterstützung bei Fortbildungen.</li>
                    <li><strong>Vielfältige Weiterbildungsmöglichkeiten:</strong> Wir legen großen Wert auf eine fundierte Ausbildung und fördern die fachliche Weiterentwicklung unserer Mitarbeiter. </li>
                    <li><strong>Perfekte Lage:</strong> Zentral in Reisbach, mit kostenlosen Parkplätzen direkt vor der Tür und Einkaufsmöglichkeiten um die Ecke. </li>
                </ul>

                <h5><strong>Deine Aufgaben:</strong></h5>
                <p>Du wirst schrittweise in alle Bereiche der hausärztlichen Versorgung eingeführt:</p>
                <ul>
                    <li>Selbstständige Patientenversorgung – vom ersten Kontakt bis zur Notfallbehandlung.</li>
                    <li>Durchführung von Ultraschalluntersuchungen, Check-ups und Hausbesuchen.</li>
                    <li>Zusammenarbeit im Team, um komplexe Fälle zu besprechen und Lösungen zu finden.</li>
                </ul>

                <h5><strong>Das bringst du mit:</strong></h5>
                <ul>
                    <li>Eine abgeschlossene Ausbildung zum Arzt/zur Ärztin, idealerweise mit ersten Erfahrungen in der Inneren und Allgemeinmedizin.</li>
                    <li>Freude am Umgang mit Menschen und Begeisterung für Allgemeinmedizin.</li>
                    <li>Ein freundliches Auftreten und Lust darauf, gemeinsam im Team zu wachsen.</li>
                </ul>

                <h5><strong>Warum zu uns?</strong></h5>
                <p>Weil wir genauso aufgeschlossen und engagiert sind wie du! Bei uns bist du Teil eines Teams, das sich gegenseitig unterstützt und den Arbeitsalltag nicht nur effizient,
                    sondern auch angenehm gestaltet. Flexible Arbeitszeiten, großzügige Urlaubsregelungen und ein wertschätzendes Miteinander sind für uns selbstverständlich.</p>

                <h5><strong>Bereit für deinen nächsten Karriereschritt?</strong></h5>
                <p>Wir freuen uns darauf, dich kennenzulernen und dir zu zeigen, warum unsere Praxis der perfekte Ort für deine Weiterbildung ist.</p>


                <h5><strong>📧  Schick uns deine Bewerbung an: </strong><a href="mailto:&#104;&#97;&#117;&#115;&#97;&#114;&#122;&#116;&#112;&#114;&#97;&#120;&#105;&#115;&#45;&#114;&#101;&#105;&#115;&#98;&#97;&#99;&#104;&#64;&#103;&#109;&#120;&#46;&#100;&#101;"> hausarztpraxis-reisbach@gmx.de</a></h5>
                <h5><strong>📞 Ruf uns an, wenn du Fragen hast – wir freuen uns auf dich! </strong></h5>


                <h5><strong>Kontakt:</strong></h5>
                <address>
                    Hausarztpraxis Reisbach<br />
                    Dr. Maderstorfer & Dr. Wicht<br />
                    Brunnleiten 9<br />
                    94419 Reisbach<br />
                </address>

                <p>Wir können es kaum erwarten, dich in unserem Team willkommen zu heißen! Werde Teil unserer Praxis und gestalte deine Zukunft in der Allgemeinmedizin gemeinsam mit uns.</p>
            </>
        )
    },
    {
        title: "Studenten (m/w/d)",
        content: (
            <>
                <p>
                <h4><strong>Hey Studierende!</strong></h4> <br />
                    Du bist auf der Suche nach einem praktischen Einblick? <br />
                    Blockpraktikum, PJ oder Schnuppertage – wir sind offen für dich! <br />
                    Hier hast du die Chance, das, was du im Studium gelernt hast, direkt in die Praxis umzusetzen.
                    Zuerst begleitest du uns, und dann wirst du selbst Teil des Teams und übernimmst Verantwortung
                    für die Patientenversorgung.
                </p>
                <p>
                    In der Allgemeinmedizin gibt es immer etwas Neues zu entdecken – Langweile? Kennen wir nicht! 😉
                    Melde dich bei uns und werde Teil eines dynamischen Teams!
                </p>

                <h5><strong>📞 Ruf uns an, wenn du Fragen hast – wir freuen uns auf dich! </strong></h5> <br />
                <h5><strong>Kontakt:</strong></h5>
                <address>
                    Hausarztpraxis Reisbach<br />
                    Dr. Maderstorfer & Dr. Wicht<br />
                    Brunnleiten 9<br />
                    94419 Reisbach<br />
                </address>
            </>
        )
    }
];

const Stellen = () => {
    return (

            <div className="content-container common-width full-height"> 
                <h2><strong>Stellen</strong></h2>
                <Accordion>
                    {jobDescriptions.map((job, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header><strong>{job.title}</strong></Accordion.Header>
                            <Accordion.Body>{job.content}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        
    );
};

export default Stellen;
