import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../navbar.css';

const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="col-1">
      <div className="navbar-container">
        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} id="v-pills-home-tab" to="/">Startseite</Link>
          {/*<button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" disabled>Aktuelles</button>
          <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" disabled>Team</button>
          <button className="nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" disabled>Praxis</button>*/}
          <Link className={`nav-link ${pathname === '/unsereLeistungen' ? 'active' : ''}`} id="v-pills-settings-tab" to="/unsereLeistungen">Leistungen</Link>
          <Link className={`nav-link ${pathname === '/rezeptanfrage' ? 'active' : ''}`} id="v-pills-settings-tab" to="/rezeptanfrage">Rezeptanfragen</Link>
          <Link className={`nav-link ${pathname === '/Stellen' ? 'active' : ''}`} id="v-pills-impressum-tab" to="/Stellen">Stellen</Link>
          <Link className={`nav-link ${pathname === '/impressum' ? 'active' : ''}`} id="v-pills-impressum-tab" to="/impressum">Impressum</Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
