import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StellenContent from './StellenContent';

const StellenPage = () => {
    return (
        <div>

            <StellenContent />

        </div>
    );
}

export default StellenPage;