import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Cards from './components/cards';
import DefaultHeader from './components/DefaultHeader';
import ImpressumPage from './components/ImpressumPage';
import Footer from './components/Footer';
import RezeptanfragePage from './components/RezeptanfragePage'; 
import StellenPage from './components/StellenPage';
import UnsereLeistungenPage from './components/UnsereLeistungenPage';

import "bootstrap/dist/css/bootstrap.min.css";
import './global.css'; // Importieren Sie die globalen Stile



class App extends Component {
  state = {};

  render() {
    return (
      <Router>
        <div className="app-container">
          <Navbar />
          <Routes>
            <Route 
              path="/" 
              element={
                <>
                  <DefaultHeader />
                  <Cards />
                </>
              } 
            />
            <Route 
              path="/impressum" 
              element={
                <>
                  <DefaultHeader />
                  <ImpressumPage />
                </>
              } 
            />
    <Route 
                path="/rezeptanfrage" 
                element={
                  <>
                    <DefaultHeader />
                    <RezeptanfragePage />
                  </>
                } 
              />
                  <Route 
                path="/Stellen" 
                element={
                  <>
                    <DefaultHeader />
                    <StellenPage />
                  </>
                } 
              />
   <Route 
                path="/unsereLeistungen" 
                element={
                  <>
                    <DefaultHeader />
                    <UnsereLeistungenPage />
                  </>
                } 
              />
          </Routes>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;