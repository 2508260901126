import React, { Component } from "react";
import { Card, Carousel } from "react-bootstrap";
import '../global.css'; // Importieren Sie die globalen Stile

class Cards extends Component {
  render() {
    return (
      <div className="container-fluid full-height ">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 card-top-margin common-width"> 
            <Card className="text-center mb-3 mt-0"> 
              <Card.Body>
                <Card.Title>Liebe Patienten!</Card.Title>
                <Card.Text>
                  Herzlich Willkommen in Ihrer Allgemeinarztpraxis im Herzen von Reisbach/Niederbayern.
                </Card.Text>
              </Card.Body>
            </Card>
            <div className="carousel-container"> 
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/Bild1.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3>Patientenparkplatz und Eingang</h3>
                    <p>Parkplätze befinden sich direkt vor unserem Eingang.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/Bild2.jpg"
                    alt="Second slide"
                  />
                </Carousel.Item>
              </Carousel>
      {/*<div className="carousel-textbox">
                <h5>Bitte beachten Sie,</h5>
                <p>diese Seite wird derzeit überarbeitet.</p>
              </div>*/}
            </div>
          
              <Card className="card-bottom-margin">
                <Card.Body>
                  <Card.Text>
                    Wir begrüßen Sie sehr herzlich auf der Internetseite unserer Allgemeinarztpraxis Dr. med. Armin Maderstorfer und Dr. med. Albert Wicht. <br/>
                    Unsere schöne, barrierefreie und behindertengerechte Hausarztpraxis liegt im Herzen von Niederbayern in Reisbach bei Dingolfing.  <br/> 
                    Auch ausreichend Parkmöglichkeiten sind direkt vor der Praxis vorhanden. <br/> Nehmen Sie sich gerne Zeit und verschaffen sich einen ersten Eindruck von unserer Praxis und unseren Leistungen. 
                  </Card.Text>
                </Card.Body>
              </Card>
              <div className="map-opening-card-container">
                <Card className="map-opening-card">
                  <Card.Body className="d-flex flex-column flex-md-row">
                    <div className="map-section">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.9449204704574!2d12.707516816134194!3d48.55822427925756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477566c6d9b4d013%3A0xf4f1d0f8b7e0c8b7!2sBrunnleiten%209%2C%2094419%20Reisbach%2C%20Germany!5e0!3m2!1sen!2sus!4v1622721383246!5m2!1sen!2sus"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Map"
                      ></iframe>
                    </div>
                    <div className="opening-times-section">
                      <h5>Allgemeinarztpraxis Reisbach</h5>
                      <div className="opening-hours-table">
                        
                        <div className="hours"> Brunnleiten 9 </div>
                        <div className="hours"> 94419 Reisbach</div>
               
                        
                        <div className="row">
                        <div className="day"> Telefon:</div>
                        <div className="hours">08734/938822</div>
                        </div>
                        <div className="row">
                        <div className="day"> Fax:</div>
                        <div className="hours">08734/9378330</div>
              
                        </div>
                      
                      <h5>Unsere Öffnungszeiten:</h5>
                     <div className="opening-hours-table">
                       <div className="row">
                         <div className="day">Montag:</div>
                         <div className="hours">7:30 - 18:00</div>
                       </div>
                       <div className="row">
                         <div className="day">Dienstag:</div>
                         <div className="hours">7:30 - 12:00 & 15:00 - 18:00</div>
                       </div>
                       <div className="row">
                         <div className="day">Mittwoch:</div>
                         <div className="hours">7:30 - 12:00</div>
                       </div>
                       <div className="row">
                         <div className="day">Donnerstag:</div>
                         <div className="hours">7:30 - 12:00 & 16:00 - 18:00</div>
                       </div>
                       <div className="row">
                         <div className="day">Freitag:</div>
                         <div className="hours">7:30 - 13:00</div>
                       </div>
                     </div>
                    </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Cards;
